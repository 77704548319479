import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useCookies } from 'react-cookie';
import { useStaticQuery, graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';

import W from '../styled/Wrapper';

import RichText from '../shared/RichText';

import CloseSvg from '../../assets/x3.svg';
import ArrowSvg from '../../assets/arrow.svg';

import Terms from './Terms';

const Wrapper = styled(W)`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: ${({ show }) => (show ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition: opacity 0.15s ease-in-out;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorBg};
`;

const Inner = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 2px solid ${({ theme }) => theme.colorMain};
  transition: height 0.25s ease-in-out;
  height: auto;

  @media only screen and (max-width: 830px) {
    border-top: 1px solid ${({ theme }) => theme.colorMain};
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media only screen and (max-width: 600px) {
    border-top: 1px solid ${({ theme }) => theme.colorMain};
    padding-top: 7px;
    padding-bottom: 7px;
  }
`;

const AnotherWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  @media only screen and (max-width: 810px) {
    align-items: flex-start;
  }
`;

const Text = styled.p`
  font-size: 16px;
  line-height: 22px;
  flex-grow: 1;
  padding-right: 20px;
  opacity: ${({ showTerms }) => (showTerms ? 0 : 1)};
  pointer-events: ${({ showTerms }) => (showTerms ? 'none' : 'auto')};
  max-width: 610px;
  
  @media only screen and (max-width: 830px) {
    font-size: 19px;
    line-height: 21px;
    max-width: 490px;
  }

  @media only screen and (max-width: 600px) {
    font-size: 13px;
    line-height: 16px;
    max-width: calc(100vw - 100px);
  }

  a {
    text-decoration: underline;
  }
`;

const Buttons = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
`;

const CloseButton = styled.button``;

const OkButton = styled.div`
  font-family: ${({ theme }) => theme.fontSerif};
  font-size: 27px;
  line-height: 32px;
  border: 2px solid ${({ theme }) => theme.colorMain} !important;
  border-radius: 5px;
  padding: 5px 20px 1px;

  @media only screen and (max-width: 830px) {
    font-size: 18px;
    line-height: 22px;
    padding: 3px 6px 0;
    border: 1px solid ${({ theme }) => theme.colorMain} !important;
  }
`;

const CookieNotice = ({ isEn }) => {
  const general = useStaticQuery(graphql`
    query generalPl {
      med {
        pl: general(
          where: { id: "ckjzk9y6w2vbv0a069puimmzp" }
          locales: pl_PL
        ) {
          cookieNotice
          cookieNoticeMobile
          policy {
            html
            markdown
            raw
          }
          cookieMessage {
            html
            markdown
            raw
          }
        }
        en: general(where: { id: "ckjzk9y6w2vbv0a069puimmzp" }, locales: en) {
          cookieNotice
          cookieNoticeMobile
          policy {
            html
            markdown
            raw
          }
          cookieMessage {
            html
            markdown
            raw
          }
        }
      }
    }
  `);

  const [cookies, setCookie] = useCookies(['m-cn']);
  const [show, setShow] = useState(false);
  const [showTerms, setShowTerms] = useState(false);
  const [data, setData] = useState(false);

  const isMob = useMediaQuery({
    query: '(max-width: 810px)',
  });

  const handleCloseClick = () => {
    setShow(false);
    setCookie('m-cn', 1, {
      path: '/',
      expires: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });
  };

  const handleTermsClick = () => {
    setShowTerms(!showTerms);
  };

  useEffect(() => {
    document.body.style.paddingBottom = show ? `50px` : '0px';
  }, [show]);

  useEffect(() => {
    setData(isEn ? general.med.en : general.med.pl);
  }, [isEn]);

  useEffect(() => {
    if (cookies['m-cn']) {
      setShow(false);
    } else {
      setShow(true);
    }
  }, []);

  return (
    <Wrapper show={show}>
      <Inner showTerms={showTerms}>
        <AnotherWrapper>
          {data && (
            <Text showTerms={showTerms} as="div">
              <RichText
                content={data.cookieMessage.raw}
                firstParMargin
                small
                noStyles
              />
            </Text>
          )}
          <Buttons>
            <CloseButton onClick={handleCloseClick}>
              <OkButton>OK</OkButton>
            </CloseButton>
          </Buttons>
        </AnotherWrapper>
      </Inner>
    </Wrapper>
  );
};

export default CookieNotice;
