import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import H from '../../components/styled/Heading';

const Wrapper = styled(Link)`
  margin-left: 32px;
  width: 51px;
  @media only screen and (max-width: 810px) {
    width: 30px;
    margin-left: 16px;
  }
`;

const Label = styled(H)`
  font-family: ${({ theme }) => theme.fontSans};
  display: ${({ show }) => (show ? 'block' : 'none')};
`;


const LangSwitcher = ({ isEn, to, location, set }) => {
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    set({ isEn: isEn });
  }, [isEn]);

  return (
    <Wrapper
      to={to.indexOf('/undefined/') > -1 ? to.replace('/undefined/', '/') : to}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Label show={isEn} hovered={hovered} pl>
        PL
      </Label>
      <Label as="span" size={6} show={!isEn} hovered={hovered} right>
        En
      </Label>
    </Wrapper>
  );
};

export default LangSwitcher;
