import React from 'react';
import styled from 'styled-components';
import { RichText } from '@graphcms/rich-text-react-renderer';

const Wrapper = styled.span`
  font-size: 24px;
  line-height: 29px;
  white-space: pre-wrap;

  a {
    text-decoration: underline;
  }

  p {
    margin-bottom: 30px;
  }

  @media only screen and (max-width: 810px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const BigRichText = ({ content, firstParMargin, noStyles }) => {

  return noStyles ? (
    <RichText content={content}/>

  ) : (
    <Wrapper firstParMargin={firstParMargin}>
      <RichText content={content} />
    </Wrapper>
  );
};

export default BigRichText;
