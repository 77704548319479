import styled from 'styled-components';

const Wrapper = styled.div`
  padding-left: 24px;
  padding-right: 24px;
  min-height: 1px;

  @media only screen and (max-device-width: 810px) {
    padding-left: 14px;
    padding-right: 14px;
  }
`;

export default Wrapper;
