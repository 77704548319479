import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';

const plMenu = [
  {
    to: '/info',
    label: 'Info',
  },
  {
    to: '/works',
    label: 'Works',
  },
  {
    to: '/updates',
    label: 'Updates',
  },
  // {
  //   to: '/team',
  //   label: 'Team',
  // },
  {
    to: '/contact',
    label: 'Contact',
  },
];
const enMenu = [
  {
    to: '/en/info',
    label: 'Info',
  },
  {
    to: '/en/works',
    label: 'Works',
  },
  {
    to: '/en/updates',
    label: 'Updates',
  },
  // {
  //   to: '/en/team',
  //   label: 'Team',
  // },
  {
    to: '/en/contact',
    label: 'Contact',
  },
];

const Outer = styled.div`
  position: absolute;
  left: 80px;
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};

  @media only screen and (max-width: 1100px) {
    left: 0;
    top: 50px;
    background-color: ${({ theme }) => theme.colorBg};
    overflow: hidden;
    width: calc(100%);
    transition: height 0.25s ease-in-out;
    padding: 0 35px;
    height: 0;

    &:after {
      content: '';
      height: 1px;
      width: calc(100% - 70px);
      position: absolute;
      bottom: 0;
      left: 35px;
      background-color: ${({ theme }) => theme.colorMain};
    }
  }

  @media only screen and (max-width: 810px) {
    left: 0;
    padding: 0 14px;

    &:after {
      content: '';
      width: calc(100% - 28px);
      left: 14px;
    }
  }
`;

const Wrapper = styled.div`
  display: flex;
  padding: 0 40px 0 0;
  opacity: ${({ open }) => (open ? 1 : 0)};
  pointer-events: ${({ open }) => (open ? 'auto' : 'none')};
  transition: opacity 0.15s ease-in-out;

  @media only screen and (max-width: 1100px) {
    display: block;
    opacity: 1;
    padding: 10px 0;
    width: 100%;
  }
`;

const Item = styled(Link)`
  margin: 0 22px;
  font-size: 24px;
  line-height: 19px;
  text-transform: uppercase;

  @media only screen and (max-width: 1100px) {
    font-size: 24px;
    line-height: 29px;
    display: block;
    margin: 4px 0;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline;
    }
  }
`;

const MenuOverlay = ({ open, isEn, location, set }) => {
  const items = isEn ? enMenu : plMenu;

  const [elRef, { height }] = useMeasure({ polyfill: ResizeObserver });

  const isMob = useMediaQuery({
    query: '(max-width: 1200px)',
  });

  useEffect(() => {
    if (isMob) {
      document.body.style.paddingTop = open ? `${height}px` : '0px';
    }
  }, [open, isMob]);

  useEffect(() => {
    set({ menuOpen: false, showcase: false});
  }, [location.pathname]);

  return (
    <Outer
      style={isMob ? { height: open ? `${height}px` : '0px' } : {}}
      open={open}
    >
      <Wrapper open={open} ref={elRef}>
        {items.map(item => {
          return (
            <Item key={item.to} to={item.to}>
              {item.label}
            </Item>
          );
        })}
      </Wrapper>
    </Outer>
  );
};

export default MenuOverlay;
