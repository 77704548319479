import React from 'react';
import styled from 'styled-components';

import W from '../styled/Wrapper';
import H from '../styled/Heading';

import RichText from '../shared/RichText';

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  height: 50vh;
  width: 100%;
  padding-top: 2px;
  padding-bottom: 2px;
  transition: transform 0.25s ease-in-out;
  transform: translateY(${({ show }) => (show ? '0vh' : '50vh')});
  overflow-y: scroll;
`;

const Heading = styled(H)`
  pointer-events: ${({showCN}) => showCN ? 'auto' : 'none'};
  padding: 10px 0 30px;
`;

const Inner = styled.div`
  width: 50%;
  pointer-events: ${({showCN}) => showCN ? 'auto' : 'none'};

  @media only screen and (max-width: 767px) {
    width: calc(100% - 28px);
  }
`;

const Terms = ({ show, policy, showCN }) => {
  return (
    <Wrapper show={show}>
      <Heading size={3} showCN={showCN}>Polityka prywatności</Heading>

      <Inner showCN={showCN}>
        <RichText
          content={policy.raw}
          firstParMargin
          small
        />
      </Inner>
    </Wrapper>
  );
};

export default Terms;
