export default {
  white: '#E4E4E4',
  black: '#000000',
  fontSerif: 'cirka, serif',
  fontSans: 'lazzer, sans-serif',
  colorBg: '#E4E4E4',
  bolorBgLight: 'rgba(256, 256, 256, 0.2)',
  colorMain: '#000000',
  colorBgInverted: '#000000',
  colorMainInverted: '#E4E4E4',
  maxWidth: '1360px'
};