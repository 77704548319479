import React, { useEffect } from 'react';
import { GlobalStyle } from './GlobalStyle';
import { ThemeProvider } from 'styled-components';
import theme from '../helpers/theme';
import themeWhite from '../helpers/theme-white';
import themeBlack from '../helpers/theme-black';
import ContextConsumer, { ContextProviderComponent } from '../helpers/context';
import { CookiesProvider } from 'react-cookie';

import '../fonts/fonts.css';

import Header from '../components/layout/Header';
import Main from '../components/layout/Main';

const Layout = ({ children, location }) => {
  const handleRe = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  const isEn =
    location.pathname.indexOf('/en/') > -1 ||
    location.pathname.endsWith('/en/') ||
    location.pathname.endsWith('/en');

  const isHome =
    location.pathname === '/' ||
    location.pathname === '/en' ||
    location.pathname === '/en/';

  useEffect(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', handleRe, false);
    return () => {
      window.removeEventListener('resize', handleRe);
    };
  }, []);
  useEffect(() => {
    document.body.classList.remove('showoff');
    document.documentElement.classList.remove('showoff');
  }, [location.pathname]);

  return (
    <React.Fragment>
      <CookiesProvider>
        <ContextProviderComponent>
          <ContextConsumer>
            {({ data, set }) => {
              return (
                <ThemeProvider
                  theme={
                    data.theme === 'black'
                      ? themeBlack
                      : data.theme === 'white'
                      ? themeWhite
                      : theme
                  }
                >
                  <GlobalStyle />
                  <Header
                    location={location}
                    isEn={isEn}
                    isHome={isHome}
                    inverted={data.inverted}
                    theme={data.theme}
                    setContext={set}
                  />
                  <Main>{children}</Main>
                </ThemeProvider>
              );
            }}
          </ContextConsumer>
        </ContextProviderComponent>
      </CookiesProvider>
    </React.Fragment>
  );
};

export default Layout;
