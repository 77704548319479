import styled from 'styled-components';

const Heading = styled.h2`
  font-size: ${({ size }) => {
    switch (size) {
      case 1:
        return '121px';
      case 2:
        return '81px';
      case 3:
        return '54px';
      case 4:
        return '36px';
      // new styles 5 and 6 
      case 5:
        return '50px'; 
      case 6:
        return '40px';
      default:
        return '36px';
    }
  }};

  line-height: ${({ size }) => {
    switch (size) {
      case 1:
        return '1';
      case 2:
        return '97px';
      case 3:
        return '56px';
      case 4:
        return '43px';
        // new styles 5 and 6 
      case 5:
        return '60px';
      case 6:
        return '48px';
      default:
        return '43px';
    }
  }};

  @media only screen and (max-width: 810px) {
    font-size: ${({ size }) => {
      switch (size) {
        case 1:
          return '54px';
        case 2:
          return '40px';
        case 3:
          return '24px';
        case 4:
          return '24px';
        // new styles 5 and 6 
        case 5:
          return '30px'; 
        case 6:
          return '24px';
        default:
          return '24px';
      }
    }};

    line-height: ${({ size }) => {
      switch (size) {
        case 1:
          return '65px';
        case 2:
          return '43px';
        case 3:
          return '29px';
        case 4:
          return '25px';
          // new styles 5 and 6 
        case 5:
          return '33px'; 
        case 6:
          return '25px';
        default:
          return '25px';
      }
    }};
  }

`;

export default Heading;
