import React, { useEffect } from 'react';
import styled from 'styled-components';
import ContextConsumer from '../../helpers/context';
import { Link } from 'gatsby';
import { useCookies } from 'react-cookie';

import CloseSvg from '../../assets/x3.svg';
import CloseBigSvg from '../../assets/close.svg';

import H from '../styled/Heading';

import W from '../styled/Wrapper';

import Menu from './MenuOverlay';
import CookieNotice from './CookieNotice';
import LangSwitcher from '../shared/LangSwitcher';

import LogoSvg from '../../assets/Mediocre-logo-header.svg';
import LogoMobileSvg from '../../assets/Mediocre-logo-s.svg';

const Wrapper = styled(W)`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  color: ${({ theme }) => theme.colorMain};
  width: 100%;
  background-color: ${({ theme }) => theme.colorBg};
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colorMain};

  @media only screen and (max-width: 1100px) {
    height: 50px;
    border-width: 1px;
    align-items: left;
  }
`;

const Button = styled(H)`
  width: 120px;
  text-align: left;
`;

const Close = styled(CloseSvg)`
  height: 24px;
  width: 24px;
  line-height: 10px;

  @media only screen and (max-width: 1100px) {
    height: 16px;
    width: 16px;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
`;

const LogoWrapper = styled(({ show, isHome, ...rest }) => <Link {...rest} />)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: ${({ show, isHome }) => (show && !isHome ? 1 : 0)};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
  transition: opacity 0.1s ease-in-out;

  @media only screen and (max-width: 1100px) {
    opacity: ${({ isHome }) => (isHome ? 0 : 1)};
    pointer-events: auto;
  }
`;

const LogoDt = styled(LogoSvg)`
  width: auto;
  height: 30px;

  @media only screen and (max-width: 810px) {
    display: none;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
`;

const LogoMobile = styled(LogoMobileSvg)`
  display: none;

  @media only screen and (max-width: 810px) {
    display: block;
    width: auto;
    height: 30px;
  }

  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
`;

const RightWrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`;

const ShowcaseWrapper = styled.div`
  opacity: ${({ isHome }) => (isHome ? 1 : 0)};
  pointer-events: ${({ show, isHome }) =>
    show ? 'none' : isHome ? 'auto' : 'none'};
  transition: opacity 0.1s ease-in-out;
  white-space: nowrap;

  svg {
    margin-top: -10px;
  }
`;

const Title = styled(H)`
  display: ${({ hide }) => (hide ? 'none' : 'block')};
  margin-right: 20px;
`;

const CloseBtn = styled(H)`
  display: ${({ hide }) => (hide ? 'flex' : 'none')};
  align-items: center;
  margin-right: 20px;
`;

const CloseBig = styled(CloseBigSvg)`
  margin-right: 12px;
  path,
  rect {
    fill: ${({ theme }) => theme.colorMain} !important;
  }
  @media only screen and (max-width: 1100px) {
    width: 18px;
    height: 18px;
    margin-right: 6px;
  }
`;
const InvertButton = styled.button`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: ${({ col }) => {
    if (col === 'green') {
      return '#00B653';
    } else if (col === 'white') {
      return '#e4e4e4';
    } else {
      return '#000000';
    }
  }};

  transition: transform 0.15s ease-in-out;
  margin-left: 10px;
  flex-shrink: 0;
  
  
  @media only screen and (max-width: 1100px) {
    width: 24px;
    height: 24px;
    margin-left: 0;
    display: ${({ showMob }) => showMob ? 'block' : 'none'};
  }
`;

const Header = ({ location, isEn, isHome, theme, setContext }) => {
  const [cookies, setCookie] = useCookies(['m-theme-2']);

  useEffect(() => {
    setCookie('m-theme-2', theme, { path: '/', expires: new Date(Date.now() + 30*24*60*60*1000)});

    if (theme === 'green') {
      document.body.style.backgroundColor = '#00B653';
    } else if (theme === 'white') {
      document.body.style.backgroundColor = '#e4e4e4';
    } else {
      document.body.style.backgroundColor = '#000000';
    }
  }, [theme]);


  useEffect(() => {
    if (cookies['m-theme-2'] && cookies['m-theme-2'] !== theme) {
      setContext({ theme: cookies['m-theme-2'] });
    }
  }, []);

  return (
    <ContextConsumer>
      {({ data, set }) => (
        <Wrapper as="header">
          <Inner>
            <Button
              size={5}
              as="button"
              onClick={() => set({ menuOpen: !data.menuOpen })}
            >
              {data.menuOpen ? <Close /> : 'Menu'}
            </Button>
            <Menu
              open={data.menuOpen}
              isEn={isEn}
              location={location}
              set={set}
            />
            <LogoWrapper
              show={!data.menuOpen}
              isHome={isHome}
              to={isEn ? '/en' : '/'}
            >
              <LogoDt />
              <LogoMobile />
            </LogoWrapper>
            <RightWrapper>
              <ShowcaseWrapper
                as="button"
                isHome={isHome}
                onClick={() => set({ showcase: !data.showcase })}
              >
                <Title as="span" size={5} hide={data.showcase}>
                  Show-off
                </Title>
                <CloseBtn as="span" size={5} hide={data.showcase}>
                  <CloseBig />
                  Close
                </CloseBtn>
              </ShowcaseWrapper>
              {theme !== 'black' && (
                <InvertButton
                  col={'black'}
                  onClick={() => set({ theme: 'black' })}
                  showMob={theme == 'green'}
                ></InvertButton>
              )}
              {theme !== 'white' && (
                <InvertButton
                  col={'white'}
                  onClick={() => set({ theme: 'white' })}
                  showMob={theme == 'black'}
                ></InvertButton>
              )}
              {theme !== 'green' && (
                <InvertButton
                  col={'green'}
                  onClick={() => set({ theme: 'green' })}
                  showMob={theme == 'white'}
                ></InvertButton>
              )}
              <LangSwitcher isEn={isEn} to={data.langTo} set={set} />
            </RightWrapper>
          </Inner>
          <CookieNotice isEn={isEn} />
        </Wrapper>
      )}
    </ContextConsumer>
  );
};

export default Header;
