import styled from 'styled-components';

const Main = styled.main`
  position: relative;
  background-color: ${({ theme }) => theme.colorBg};
  color: ${({ theme }) => theme.colorMain};
  padding-top: 88px;
  padding-bottom: 30px;
  z-index: 0;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);

  @media only screen and (max-width: 810px) {
    padding-top: 50px;
    padding-bottom: 10px;
  }
`;

export default Main;
